import { Button, Form, FormInstance, Modal, Row, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import FormUIBuilder from "components/FormUIBuilder";
import { edu_form_data } from "../step_element/edu_info";
import { IStudent } from "models/student";
import checkPermission from "utils/check_permission";

interface DataType {
  name: string;
  value: ReactNode;
  value2?: ReactNode;
  value3?: ReactNode;
}

type EduInfoViewPropsType = {
  data: IStudent | undefined,
  saveMutation: UseMutationResult<any, AxiosError<any, any>, void, unknown>,
  form: FormInstance
}

const EduInfoView: FC<EduInfoViewPropsType> = ({ data, saveMutation, form }): JSX.Element => {

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const columns: ColumnsType<DataType> = [
    {
      title: t("Value"),
      dataIndex: "name",
      rowScope: "row",
    },
    {
      title: t("Value"),
      dataIndex: "value",
      onCell: (_, index) => ({
        colSpan: 1
      }),
    },
    {
      title: t("Name2"),
      dataIndex: "value2",
      onCell: (_, index) => ({ colSpan: 1 }),
      className: "bg-[#FAFAFA] font-bold"
    },
    {
      title: t("Name3"),
      dataIndex: "value3",
      onCell: (_, index) => ({ colSpan: 1 }),
    },
  ];

  const tableData: DataType[] = [
    {
      name: t("Faculty"),
      value: data?.faculty?.name ?? "-",
      value2: t("Direction"),
      value3: data?.direction?.name ?? "-",
    },
    {
      name: t("Education plan"),
      value: data?.eduPlan?.name ?? "-",
      value2: t("Group"),
      value3: data?.group?.unical_name ?? "-",
    },
    {
      name: t("Education type"),
      value: data?.eduType?.name ?? "-",
      value2: t("Education form"),
      value3: data?.eduForm?.name ?? "-",
    },
    {
      name: t("Education year"),
      value: data?.eduYear?.name ?? "-",
      value2: t("Course"),
      value3: data?.course?.name ?? "-",
    },
    {
      name: t("Education language"),
      value: data?.eduLang?.name ?? "-",
      value2: t("Payment form"),
      value3: data?.is_contract ? t("Contract") : t("Grand"),
    }
  ]

  useEffect(() => {
    if (saveMutation.isSuccess) setIsModalOpen(false)
  }, [saveMutation.isSuccess])

  return (
    <div className="px-[24px] pt-[30px] pb-[10px]">
      <div className="flex justify-between items-center mb-[12px]">
        <p className="font-medium text-[16px]">{t("Education information")}</p>
        {/* { checkPermission("student_update") ? <Button onClick={() => setIsModalOpen(true)}>{t("Edit")}</Button> : null} */}
      </div>
      <Table
        columns={columns}
        bordered
        dataSource={tableData}
        showHeader={false}
        pagination={false}
      />


      {/* edit form */}
      <Modal
        title={t("Education information")}
        okText={t("Submit")}
        cancelText={t("Cancel")}
        width={1000}
        open={isModalOpen}
        onOk={() => form.submit()}
        onCancel={() => setIsModalOpen(false)}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={(values) => saveMutation.mutate(values)}
        >
          <Row gutter={[24, 0]} >
            <FormUIBuilder data={edu_form_data} form={form} load={true} />
          </Row>
        </Form>
      </Modal>
    </div>
  )
}
export default EduInfoView;