import { Button, Form, FormInstance, Modal, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FILE_URL } from "config/utils";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import PassportElements from "pages/users/form_elements/passport_elements";
import checkPermission from "utils/check_permission";

interface DataType {
    name: string;
    value: ReactNode;
    value2?: ReactNode;
    value3?: ReactNode;
}

const PassportInfoUserView = ({data, form, saveMutation} : {data: any, form: FormInstance, saveMutation: UseMutationResult<any, AxiosError<any, any>, void, unknown>}) => {

    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const sharedOnCell = (_: DataType, index: number | undefined, type?: "last") => {
        if(index || index == 0){
            if (index >= 4) {
                return { colSpan: 0, rowSpan: 0 };
            }
        }
        return {};
    };

    const columns: ColumnsType<DataType> = [
        {
          title: t("Surname"),
          dataIndex: "name",
          rowScope: "row",
        },
        {
          title: t("Value"),
          dataIndex: "value",
          onCell: (_, index) => ({
            colSpan: (index == 4 || index == 5) ? 3 : 1
          }),
        },
        {
          title: t("Name2"),
          dataIndex: "value2",
          onCell: (_, index) => sharedOnCell(_, index),
          className: "bg-[#FAFAFA]"
        },
        {
          title: t("Name3"),
          dataIndex: "value3",
          onCell: (_, index) => sharedOnCell(_, index, "last"),
        },
    ];

    const tableData: DataType[] = [
      {
        name: t("Birthday"),
        value: data?.profile?.birthday,
        value2: t("Gender"),
        value3: data?.profile?.gender == 1 ? t("Male") : data?.profile?.gender === 0 ? t("Female") : ""
      },
      {
        name: t("Citizenship"),
        value: data?.citizenship?.name,
        value2: t("Nationality"),
        value3: data?.nationality?.name
      },
      {
        name: t("Document series and number"),
        value: `${data?.profile?.passport_serial ? data?.profile?.passport_serial : ""}  ${data?.profile?.passport_number ? data?.profile?.passport_number : ""}`,
        value2: t("JSHSHIR"),
        value3: data?.profile?.passport_pin
      },
      {
        name: t("Date of issue of the document"),
        value: data?.profile?.passport_given_date,
        value2: t("Validity period" ),
        value3: data?.profile?.passport_issued_date
      },
      {
        name: t("Address"),
        value: data?.profile?.passport_given_by
      },
      {
        name: t("Document file"),
        value: data?.profile?.passport_file ? <a href={FILE_URL + data?.profile?.passport_file} target="_blank">{t("Download")}</a> : "----"
      },
    ];

    useEffect(() => {
      if(saveMutation.isSuccess) setIsModalOpen(false)
    }, [saveMutation.isSuccess])



    return (
        <div className="px-[24px] pt-[30px] pb-[10px]">
            <div className="flex justify-between items-center mb-[12px]">
                <p className="font-medium text-[16px]">{t("Identity document")}</p>
                { checkPermission("user_update") ? <Button onClick={() => setIsModalOpen(true)}>{t("Edit")}</Button> : null}
            </div>
            <Table
                columns={columns}
                bordered
                dataSource={tableData}
                showHeader={false}
                pagination={false}
            />


            {/* edit form */}
            <Modal
              title={t("Identity document")}
              okText={t("Submit")}
              cancelText={t("Cancel")}
              width={1000}
              open={isModalOpen}
              onOk={() => form.submit()}
              onCancel={() => setIsModalOpen(false)}
            >
              <Form
                  form={form}
                  name="basic"
                  layout="vertical"
                  onFinish={(values) => saveMutation.mutate(values)}
              >
                  <PassportElements form={form} passport_file={data?.profile?.passport_file} />
              </Form>
          </Modal>
        </div>
    )
}
export default PassportInfoUserView;