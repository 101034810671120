import { Button, Divider, Form, FormInstance, Modal, Row, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import FormUIBuilder from "components/FormUIBuilder";
import { address_form_data, per_address_data } from "../step_element/address_info";
import { IStudent } from "models/student";
import checkPermission from "utils/check_permission";

interface DataType {
  name: string;
  value: ReactNode;
  value2?: ReactNode;
  value3?: ReactNode;
}

const AddressInfoView: FC<{ data: IStudent | undefined, form: FormInstance, saveMutation: UseMutationResult<any, AxiosError<any, any>, void, unknown> }> = ({ data, form, saveMutation }): JSX.Element => {

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const sharedOnCell = (_: DataType, index: number | undefined, type?: "last") => {
    if (index || index == 0) {
      if (index == 1 || index == 5) {
        return {}
      } else {
        return { colSpan: 0 };
      }
      // if (index != 4) {
      //   return { colSpan: 0 };
      // }
    }
    return {};
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t("Value"),
      dataIndex: "name",
      rowScope: "row",
      onCell: (_, i) => ({
        colSpan: (i == 3) ? 0 : 1
      })
    },
    {
      title: t("Value"),
      dataIndex: "value",
      onCell: (_, index) => ({
        colSpan: (index == 1 || index == 5) ? 1 : (index == 3) ? 4 : 3
      }),
    },
    {
      title: t("Name2"),
      dataIndex: "value2",
      onCell: (_, index) => sharedOnCell(_, index),
      rowScope: "row"
    },
    {
      title: t("Name3"),
      dataIndex: "value3",
      onCell: (_, index) => sharedOnCell(_, index, "last"),
    },
  ];

  const tableData: DataType[] = [
    {
      name: t("Country"),
      value: data?.country?.name ?? "-",
    },
    {
      name: t("Region"),
      value: data?.region?.name ?? "-",
      value2: t("Area"),
      value3: data?.area?.name ?? "-"
    },
    {
      name: t("Address"),
      value: data?.profile?.address ?? "-"
    },
    {
      name: t("Permanent address information"),
      value: t("Permanent address information"),
    },
    {
      name: t("Country"),
      value: data?.permanentCountry?.name ?? "-",
    },
    {
      name: t("Region"),
      value: data?.permanentRegion?.name ?? "-",
      value2: t("Area"),
      value3: data?.permanentArea?.name ?? "-"
    },
    {
      name: t("Address"),
      value: data?.profile?.permanent_address ?? "-"
    },
  ];

  useEffect(() => {
    if (saveMutation.isSuccess) setIsModalOpen(false)
  }, [saveMutation.isSuccess])

  const submitVal = () => {
    form.submit()
  }

  return (
    <div className="px-[24px] pt-[30px] pb-[10px]">
      <div className="flex justify-between items-center mb-[12px]">
        <p className="font-medium text-[16px]">{t("Residential address information")}</p>
        { checkPermission("student_update") ? <Button onClick={() => setIsModalOpen(true)}>{t("Edit")}</Button> : null}
      </div>
      <Table
        columns={columns}
        bordered
        dataSource={tableData}
        showHeader={false}
        pagination={false}
      />

      {/* edit form */}
      <Modal
        title={t("Residential address information")}
        okText={t("Submit")}
        cancelText={t("Cancel")}
        width={1000}
        open={isModalOpen}
        onOk={submitVal}
        onCancel={() => setIsModalOpen(false)}
      >
        {isModalOpen ? <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={(values) => saveMutation.mutate(values)}
        >
          <Row gutter={[24, 0]} >
            <FormUIBuilder data={address_form_data} form={form} load={true} />
          </Row>
          <Divider orientation='left' orientationMargin={0} ><p className='font-medium mt-[20px] mb-[12px]'>{t("Permanent address information")}</p></Divider>
          <Row gutter={[24, 0]} >
            <FormUIBuilder data={per_address_data} form={form} load={true} />
          </Row>
        </Form> : null}
      </Modal>
    </div>
  )
}
export default AddressInfoView;