import React from 'react';
import { Briefcase32Filled, HatGraduationFilled, PeopleCommunity32Filled, PersonAccountsFilled } from "@fluentui/react-icons";
import { Button, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'store';

const UserCountStatistika: React.FC = (): JSX.Element => {
  const {t} = useTranslation();

  const role = useAppSelector(state => state.auth.user?.active_role); 

  return (
    <div className="grid grid-cols-12 gap-4">
        <div className=" xl:col-span-3 md:col-span-6 col-span-12">
          <Link to={'/students'} className='no-underline text-black ' >
            <div className="box p-4 e-card-shadow rounded-lg hover:-translate-y-[3px] transition-all" style={{border: "1px solid #d9d9d9"}}>
              <div className="flex justify-between items-center">
                <Tag color="#EBF2FF" className="text-center py-1 px-4"><HatGraduationFilled fontSize={32} color="#386AD8"/></Tag>
                <Tag className="text-sm py-1 px-3" color="#EBF2FF"><span className='text-[#386AD8] font-medium'>20%</span></Tag>
              </div>
              <h4 className="text-[#C0C0C0] my-4">{t("Talabalar bazasi")}</h4>
              {/* <h2 className="text-4xl font-bold">1768</h2> */}
              <Button size='large' className='w-full mt-2' >{t("View student database")}</Button>
            </div>
          </Link>
        </div>
        <div className=" xl:col-span-3 md:col-span-6 col-span-12">
          <Link to={`/students${role != "dean"  ? '?faculty_id=6' : ""}`} className='no-underline text-black ' >
              <div className="box p-4 e-card-shadow rounded-lg hover:-translate-y-[3px] transition-all" style={{border: "1px solid #d9d9d9"}}>
                <div className="flex justify-between items-center">
                  <Tag color="#EFF7EF" className="text-center py-1 px-4"><PeopleCommunity32Filled color="green"/></Tag>
                  <Tag className="text-sm py-1 px-3" color="#EFF7EF"><span className='text-green-600 font-medium'>0.22%</span></Tag>
                </div>
                <h4 className="text-[#C0C0C0] my-4">{t("Database of Master's and part-time requirements")}</h4>
                {/* <h2 className="text-4xl font-bold">1057</h2> */}
                <Button size='large' className='w-full mt-2' >{t("View master's and part-time requirements database")}</Button>
              </div>
          </Link>
        </div>

        <div className=" xl:col-span-3 md:col-span-6 col-span-12">
          <Link to={'/employees'} className='no-underline text-black ' >
            <div className="box p-4 e-card-shadow rounded-lg hover:-translate-y-[3px] transition-all" style={{border: "1px solid #d9d9d9"}}>
              <div className="flex justify-between items-center">
                <Tag color="#F0EFF6" className="text-center py-1 px-4"><PersonAccountsFilled fontSize={32} color="#70679B"/></Tag>
                <Tag className="text-sm py-1 px-3" color="#F0EFF6"><span className='text-[#70679B] font-medium'>0.22%</span></Tag>
              </div>
              <h4 className="text-[#C0C0C0] my-4">{t("Employee base")}</h4>
              {/* <h2 className="text-4xl font-bold">428</h2> */}
              <Button size='large' className='w-full mt-2' >{t("View the employee database")}</Button>
            </div>
          </Link>
        </div>

        <div className=" xl:col-span-3 md:col-span-6 col-span-12">
          <Link to={'/teachers'} className='no-underline text-black ' >
            <div className="box p-4 e-card-shadow rounded-lg hover:-translate-y-[3px] transition-all" style={{border: "1px solid #d9d9d9"}}>
              <div className="flex justify-between items-center">
                <Tag color="#e1f5f5" className="text-center py-1 px-4"><Briefcase32Filled color="#46DAAF"/></Tag>
                <Tag className="text-sm py-1 px-3" color="#e1f5f5"><span className='text-[#46DAAF] font-medium'>0.22%</span></Tag>
              </div>
              <h4 className="text-[#C0C0C0] my-4">{t("Teacher database")}</h4>
              {/* <h2 className="text-4xl font-bold">289</h2> */}
              <Button size='large' className='w-full mt-2' >{t("View the database of teachers")}</Button>
            </div>
          </Link>
        </div>
      </div>
  );
};

export default UserCountStatistika;