import React, { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { prived_routes, public_routes } from "routes";
import { useAppSelector } from "store";
import checkPermission from "utils/check_permission";
import { Drawer, Menu, MenuProps } from "antd";
import IconComponent from "components/Icon";
import { TypeRoutes } from "routes/types";
import logo from "assets/images/utas-logo-new.png";
import { useTranslation } from "react-i18next";
import './style.scss';
import checkOnlyForRoles from "utils/check_only_for_roles";
import checkAllowedRole from "utils/check_allowed_roles";

type MenuItem = Required<MenuProps>['items'][number];

const SidebarByAntMenu: FC = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const location = useLocation()
  const { auth, ui } = useAppSelector(state => state);
  const [hover, setHover] = useState<boolean>(false)
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const createMenuItems = (data: TypeRoutes[]) => {
    const items: MenuProps['items'] = [];
    data.forEach(item => {
      const childItems: MenuItem[] = []
      if ((checkPermission(item.config.permission) || item.config.permission === "*") && item.config.isMenu) {
        if (item?.submenu?.length) {
          item.submenu.forEach(childItem => {
            if(childItem.config?.for_roles?.length){
              if(checkOnlyForRoles(childItem.config?.for_roles)){
                if ((checkPermission(childItem.config.permission) || childItem.config.permission === "*") && childItem.config.isMenu)
                  childItems.push({
                    key: childItem.path.split("/")[1],
                    label: <Link to={childItem?.path} className="sidebar-name">{t(childItem?.name)}</Link>,
                  })
              }
            } else {
              if(checkAllowedRole(childItem.config?.not_allowed_roles)){
                if ((checkPermission(childItem.config.permission) || childItem.config.permission === "*") && childItem.config.isMenu)
                  childItems.push({
                    key: childItem.path.split("/")[1],
                    label: <Link to={childItem?.path} className="sidebar-name">{t(childItem?.name)}</Link>,
                  })
              }
            }
          })
        }
        if(item.config?.for_roles?.length){
          if(checkOnlyForRoles(item.config?.for_roles)){
            items.push({
              key: item.path,
              label: !item?.submenu?.length ? <Link to={item.path} className="sidebar-name">{t(item?.name)}</Link> : <span className="sidebar-name">{t(item?.name)}</span>,
              icon: IconComponent(item?.config?.icon),
              children: childItems.length ? childItems : undefined,
              onTitleClick: () => { (ui.sidebar === "small" && !hover) && setHover(p => !p) }
            })
          }
        } else {
          if(checkAllowedRole(item.config?.not_allowed_roles)){
            items.push({
              key: item.path,
              label: !item?.submenu?.length ? <Link to={item.path} className="sidebar-name">{t(item?.name)}</Link> : <span className="sidebar-name">{t(item?.name)}</span>,
              icon: IconComponent(item?.config?.icon),
              children: childItems.length ? childItems : undefined,
              onTitleClick: () => { (ui.sidebar === "small" && !hover) && setHover(p => !p) }
            })
          }
        }
      }
    })
    return items
  }

  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey) {
      setOpenKeys([latestOpenKey]);
    } else {
      setOpenKeys([]);
    }
  };

  useEffect(() => {
    onOpenChange([location.pathname])
  }, []);

  const menuItems = React.useMemo(() => {
    if (auth.isAuthenticated) {
      return createMenuItems(prived_routes)
    } else {
      return createMenuItems(public_routes)
    }
  }, [i18n.language, ui.sidebar, hover])

  return (
    <>
      <div className="container-sidebar-ant sidebar-wrapper">
        <div className={`sidebar-logo ${ui.sidebar}`}>
          <img src={logo} alt="" />
          <h1>E-UTAS</h1>
        </div>
        <div className="container-menu">
          <Menu
            selectedKeys={[location.pathname, location.pathname.split("/")[1]]}
            defaultOpenKeys={[location.pathname, ...openKeys]}
            openKeys={openKeys}
            items={menuItems}
            // theme={ui.theme === "light" ? "light" : "dark"}
            mode="inline"
            inlineCollapsed={ui.sidebar === "small"}
            // onOpenChange={(e) => { ui.sidebar === "small" && setHover(true) }}
            onOpenChange={onOpenChange}
            style={{ width: ui.sidebar === "small" ? 52 : "" }}
          />
        </div>
      </div>
      <Drawer
        title={null}
        open={hover}
        onClose={() => setHover(p => !p)}
        closable={false}
        footer={null}
        placement="left"
        drawerStyle={{ padding: 0 }}
        width={256}
        bodyStyle={{ padding: "0 0.5rem" }}
      >
        <div className="sidebar-wrapper">
          <div className={`sidebar-logo`}>
            <img src={logo} alt="" />
            <h1 >E-UTAS</h1>
          </div>
          <div className="container-menu">
            <Menu
              selectedKeys={[location.pathname, location.pathname.split("/")[1]]}
              defaultOpenKeys={[location.pathname, location.pathname.split("/")[1]]}
              items={menuItems}
              mode="inline"
            />
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default React.memo(SidebarByAntMenu)