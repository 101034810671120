import { DatePicker, Select, Spin, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import { number_order } from "utils/number_orders";
import dayjs from "dayjs";
import useGetAllData from "hooks/useGetAllData";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import CustomPagination from "components/Pagination";

const LoginHitoryTable = ({user_id}: {user_id: string | undefined}) => {

    const { t } = useTranslation();
    const { urlValue, writeToUrl } = useUrlQueryParams({ currentPage: 1, perPage: 15 });

    const columns: ColumnsType<any> = [
        {
          title: "№",
          dataIndex: "order",
          width: 45,
          render: (_, __, i) => number_order(urlValue.currentPage, urlValue.perPage, Number(i), false),
        },
        {
          title: t("IP adres"),
          dataIndex: "ip",
          key: "ip",
          render: (i) => i,
        },
        {
            title: t("Operatsion sistema"),
            dataIndex: "device",
            key: "device",
            render: (i) => i,
          },
        {
          title: t("Qurilma"),
          dataIndex: "device_id",
          key: "device_id",
          render: (i) => i,
        },
        {
          title: t("Time"),
          dataIndex: "updated_at",
          key: "updated_at",
          render: (i) => dayjs.unix(i).format('DD-MM-YYYY HH:mm:ss'),
        },
        {
            title: t("Amal"),
            dataIndex: "log_in_out",
            key: "log_in_out",
            render: (i) => i === 1 ? <Tag color="blue" >Tizimga kirgan</Tag> : <Tag color="orange" >Tizimdan chiqqan</Tag>,
          }
      ]

    const { data: loginHistories, isLoading } = useGetAllData({
        queryKey: ["users/login-history", urlValue],
        url: `users/login-history/${user_id}`,
        urlParams: { 
            "per-page": urlValue.perPage, 
            page: urlValue.currentPage, 
            sort: urlValue.filter_like?.sort ?? "-id",
            date: urlValue.filter_like?.time ? dayjs(urlValue.filter_like?.time, 'DD-MM-YYYY').format('YYYY-MM-DD') : undefined,
            filter: JSON.stringify({log_in_out: urlValue.filter_like?.log_in_out})
        },
        options: {
            enabled: !!user_id,
            refetchOnWindowFocus: false,
            retry: 1
        }
    })


    return (
        <div className="px-[24px] pt-[45px] pb-[10px]">
            <Spin spinning={isLoading}>
                <div className="md:flex justify-between items-center mb-[12px]">
                    <p className="font-medium text-[16px]">{t("Tizimga kirish tarixi")}</p>
                    <div className="sm:flex gap-4">
                        <Select
                            className="w-[300px]"
                            value={urlValue.filter_like?.log_in_out}
                            onChange={(e) => writeToUrl({value: e ? e : undefined, name: "log_in_out"})}
                            placeholder="Tizimdan foydalanish filtri"
                            allowClear
                            options={[
                                { value: "1", label: 'Tizimga kirgan' },
                                { value: "2", label: 'Tizimdan chiqqan' },
                            ]}
                        />
                        <DatePicker 
                            className="w-[300px]"
                            placeholder="Sana bo'yicha filter qilish" 
                            format={"DD-MM-YYYY"}
                            value={urlValue.filter_like?.time ? dayjs(urlValue.filter_like?.time, "DD-MM-YYYY") : undefined}
                            onChange={(e) => writeToUrl({value: e ? dayjs(e).format('DD-MM-YYYY') : undefined, name: "time"})}
                        />
                    </div>

                </div>
                <Table
                    columns={columns}
                    bordered
                    dataSource={loginHistories?.items}
                    pagination={false}
                />

                {(loginHistories?._meta?.totalCount ?? 0) > 10 ? <CustomPagination totalCount={loginHistories?._meta.totalCount} currentPage={urlValue.currentPage} perPage={urlValue.perPage} /> : undefined}
            </Spin>
        </div>
    )
}
export default LoginHitoryTable;