import { FC } from "react";
import { useTranslation } from "react-i18next";
import UserCountStatistika from "../components/user_count_statistics";
import FacultyStatistcs from "../components/faculty_statistcs";
import '../style.scss'

const AdminDashboard: FC = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="dashboard bg-[#fff] min-h-full p-3">
      <UserCountStatistika />
      <div className="mt-8">
        <FacultyStatistcs />
      </div>
    </div>
  )
}

export default AdminDashboard;