import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { data as _data } from '../static_data';
import useGetData from 'hooks/useGetData';

const FacultyStatistcs: React.FC = (): JSX.Element => {
  const {t} = useTranslation();

  const {data} = useGetData({
    queryKey: ["statistics"],
    url: `statistics/faculty-statistic?expand=studentStatistic`,
  })

  const columns: ColumnsType<any> = [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t("Women"),
      dataIndex: 'studentStatistic',
      render: (e) => e?.woman,
      key: 'age',
    },
    {
      title: t('Men'),
      dataIndex: 'studentStatistic',
      render: (e) => e?.male,
      key: 'men',
    },
    {
      title: t("Barcha talabalar"),
      key: 'total_student',
      dataIndex:'studentStatistic',
      render: (e) => (e?.male ?? 0) + (e?.woman ?? 0),
    },
    {
      title: t('Teachers'),
      dataIndex: 'teachers',
      key: 'teachers',
    },
    {
      title: t("Barcha foydalanuvchilar"),
      key: 'total',
      dataIndex:'total'
    },
  ];  

  return (
      <div className="box h_16 p-4  e-card-shadow" style={{height:'auto'}}>
        <h4 className="mb-3">{t("Number of students by kafedras")}</h4>
        <Table 
          columns={columns} 
          dataSource={data?.items} 
          pagination={false} 
          scroll={{y:400}}
          summary={pageData => (
            <>
              <Table.Summary.Row className='bg-gray-100'>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>

                <Table.Summary.Cell index={1}>
                  {data?.items?.reduce((acc, cur) => acc += cur?.studentStatistic?.woman, 0)}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={2}>
                  {data?.items?.reduce((acc, cur) => acc += cur?.studentStatistic?.male, 0)}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={3}>
                  {data?.items?.reduce((acc, cur) => acc += (cur?.studentStatistic?.male + cur?.studentStatistic?.woman), 0)}
                </Table.Summary.Cell>

                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell index={5}></Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )}
    
        />
      </div>
  );
};

export default FacultyStatistcs;