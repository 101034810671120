import React, { useState } from 'react'
import Table, { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { Switch, Tag } from 'antd';
import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { changeStudentExamPermission } from '../crud/requests';
import { Notification } from 'utils/notification';
import { AxiosError } from 'axios';
import { renderExamStatus } from '..';

const sortStudent = (a: any, b: any) => {

  const nameA = a?.student?.profile?.last_name?.toUpperCase(); // ignore upper and lowercase
  const nameB = b?.student?.profile?.last_name?.toUpperCase(); // ignore upper and lowercase
  const groupA = a?.group_id;
  const groupB = b?.group_id;

  if (groupA < groupB) {
    return -1;
  }
  else if (groupA > groupB) {
    return 1;
  }
  else {
    if (nameA < nameB) {
      return -1;
    }
    else if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
};

const ExamStudentsControl: React.FC<{data: any}> = ({data}): JSX.Element => {

  const { t } = useTranslation()
  const { exam_id } = useParams()  
  const [isMark, setIsMark] = useState(false)
  const [marks, setmarks] = useState<{[std_id: number]: {a?: number, b?: number}}>({});

  const columns: ColumnsType<any> = React.useMemo(() => {

  let arr: any = [
    {
      title: "№",
      dataIndex: "order",
      render: (_: any, __: any, i: number) => i + 1,
      width: 50,
      fixed: "left",
      rowScope: "row",
    },
    {
      title: "F.I.O",
      key: "profile",
      fixed: "left",
      rowScope: "row",
      render: (e: any) => <p className='font-normal py-[5px]' >{e?.student?.profile?.last_name} {e?.student?.profile?.first_name} {e?.student?.profile?.middle_name}</p>
    },
    {
      title: t("Group"),
      key: "profile",
      render: (e: any) => <p className='font-normal py-[5px]' >{e?.group?.unical_name}</p>
    },
    {
      title: t("Ruxsati bor"),
      key: "profile",
      align: "center",
      render: (e: any) => <Switch checked={e?.finalExamTest?.status === 1} onChange={(event) => console.log(event)} />
    },
    {
      title: t("Urunishlar soni"),
      key: "profile",
      align: "center",
      render: (e: any) => e?.finalExamTest?.attends_count
    },
    {
      key: "ball",
      width: 400,
      className: "text-center",
      align: "center",
      title: "Status",
      render: (e: any) => "Test boshlanmagan"
    }
  ]

    return arr?.filter((item: any) => item);

  }, [data?.data, isMark, marks]);

  const { mutate, isLoading: clicked } = useMutation({
    mutationFn: () => changeStudentExamPermission(exam_id, marks),
    onSuccess: async (res) => {
      Notification("success", "update", res?.message)
    //   refetch();
      setIsMark(false);
      setmarks({})
    },
    onError: (error: AxiosError<any>) => {
      Notification("error", "update", error?.response?.data ? error?.response?.data?.message : "");
    },
    retry: 0,
  });

  return (
    <>
      <div>
        <span><span className="text-blck opacity-60">Talabalar soni:</span> <b>{data?.studentMark?.length}</b></span> &nbsp; / &nbsp;
        <span><span className="text-blck opacity-60">Guruh(lar):</span> {data?.groups?.map((e: any, i: number) => <Tag color='blue' className='border-1'>{e?.group?.unical_name}</Tag> )}</span>
        &nbsp;/ &nbsp;<span><span className="text-blck opacity-60">Holati:</span> {renderExamStatus(data?.status ?? 0, "py-[3px] px-[0.75rem] rounded-md")?.tag}</span>
        <Table
          columns={columns}
          dataSource={data?.studentMark?.sort(sortStudent)}
          pagination={false}
          size="middle"
          className="mt-3"
          rowClassName="py-[12px]"
          scroll={{ y: 900 }}
        />
      </div>
    </>
  )
}

export default ExamStudentsControl