import React from "react";
import UserCountStatistika from "../components/user_count_statistics";

const DeanDashboard : React.FC = () : JSX.Element => {

  return(
    <div className="dashboard bg-[#fff] min-h-full p-3">

      <UserCountStatistika />
      
    </div>
  )
}

export default DeanDashboard