import React, { useEffect, useState } from "react";
import { Row, Tag, message } from "antd";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import { useTranslation } from "react-i18next";
import HeaderExtraLayout from "components/HeaderPage/headerExtraLayout";
import { Link } from "react-router-dom";
import FilterSelect, { TypeFilterSelect } from "components/FilterSelect";
import { IGroup } from "models/education";
import Table, { ColumnsType } from "antd/es/table";
import { number_order } from "utils/number_orders";
import useGetAllData from "hooks/useGetAllData";
import checkPermission from "utils/check_permission";
import CustomPagination from "components/Pagination";
import { ExcelBtn } from "components/Buttons";
import instance from "config/_axios";
import { excelExport } from "utils/excelExport";

const GroupMarkStatistics: React.FC = (): JSX.Element => {

  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { urlValue, writeToUrl } = useUrlQueryParams({
    currentPage: 1,
    perPage: 10,
  });

  const [allData, setallData] = useState<IGroup[]>();
  const [eduSemestrSubjects, seteduSemestrSubjects] = useState<any>();
  const [searchVal, setSearchVal] = useState<string>("");
  
  const selectData: TypeFilterSelect[] = [
    {
      name: "faculty_id",
      label: "Faculty",
      url: "faculties",
      permission: "faculty_index",
      child_names: ["edu_plan_id"],
      onChange(id, item) {
        seteduSemestrSubjects([]);
      },
    },
    {
      name: "edu_plan_id",
      label: "Edu plan",
      url: "edu-plans",
      permission: "edu-plan_index",
      parent_name: "faculty_id",
      child_names: ["edu_semestr_id"],
      onChange(id, item) {
        seteduSemestrSubjects([]);
      },
    },
    {
      name: "edu_semestr_id",
      label: "Edu semestr",
      url: "edu-semestrs?expand=semestr,eduSemestrSubjects,eduSemestrSubjects.subject",
      permission: "edu-semestr_index",
      parent_name: "edu_plan_id",
      // filter: {status: "all"},
      child_names: ["edu_semestr_subject_id"],
      render: (e) => <div>{e?.semestr?.name} / {e?.name} {e?.status == 1 ? <Tag color="green" className="ml-3">Active</Tag>: ""}</div>,
      onChange(id, item) {
        writeToUrl({name: "edu_year_id", value: item?.edu_year_id});
        writeToUrl({name: "edu_semestr_id", value: id});
        seteduSemestrSubjects(item?.eduSemestrSubjects);
      },
    },
    {
      name: "exams_type_id",
      label: "Exam type",
      url: "exams-types",
      permission: "exams-type_index",
    }
  ];

  useEffect(() => {
    writeToUrl({name: "edu_semestr_id", value: undefined})
    writeToUrl({name: "edu_year_id", value: undefined});
    seteduSemestrSubjects([])
  }, [])

  const { data, isLoading } = useGetAllData<IGroup>({
    queryKey: ["groups", urlValue.perPage,urlValue.currentPage,searchVal,urlValue?.filter?.faculty_id,urlValue?.filter?.edu_plan_id, urlValue?.filter?.edu_semestr_id, urlValue?.filter?.exams_type_id],
    url: `groups?sort=-id&expand=faculty,eduPlan,lang,semestrSubject,semestrStudentCount`,
    urlParams: {
      "per-page": urlValue.perPage,
      page: urlValue.currentPage,
      edu_semestr_id: urlValue?.filter?.edu_semestr_id,
      exams_type_id: urlValue?.filter?.exams_type_id,
      edu_year_id: urlValue?.filter?.edu_year_id,
      query: searchVal ? searchVal : undefined, 
      filter: JSON.stringify(Object.keys(urlValue.filter)?.length ? {...urlValue.filter, status: 1} : undefined),
    },
    options: {
      refetchOnWindowFocus: false,
      retry: 1,
      onSuccess: (res) => {
        setallData(res?.items);
      },
    },
  });

  const columns: ColumnsType<IGroup> = [
    {
      title: "№",
      dataIndex: "order",
      width: 50,
      render: (_, __, i) =>
        number_order(
          urlValue.currentPage,
          urlValue.perPage,
          Number(i),
          isLoading
        ),
    },
    {
      title: t("Name"),
      dataIndex: "name",
      width: 180,
      key: "name",
      render: (i, e) =>
        checkPermission("group_view") ? (
          <Link to={`/group/view/${e?.id}`} className="underline text-black">
            {e?.unical_name}
          </Link>
        ) : (
          <span>{e?.unical_name}</span>
        ),
    },
    {
      title: t("Talabalar soni"),
      dataIndex: "semestrStudentCount",
      key: "semestrStudentCount",
    },
    ...(eduSemestrSubjects?.length ? eduSemestrSubjects?.map((eduSub: any) => (
      {
        title: `${eduSub?.subject?.name} (o'tdi / o'tmadi)`,
        dataIndex: "lang_id",
        key: "lang_id",
        render: (i: any, e: any) => {
          let currObj = e?.semestrSubject?.find((j: any) => j?.edu_semestr_subject_id == eduSub?.id)
          return <span>{currObj?.passed} / {currObj?.no_passed}</span>
        },
      }
    )) : [])
  ]


  const exportExcel = async () => {
    const arr: any = [];

    if (urlValue?.filter?.edu_semestr_id) {

      setLoading(true);

      const res = await instance({
        method: "GET",
        url: `groups?sort=-id&expand=faculty,eduPlan,lang,semestrSubject,semestrStudentCount`,
        params: { 
          "per-page": 0, 
          page: urlValue.currentPage,
          edu_semestr_id: urlValue?.filter?.edu_semestr_id,
          exams_type_id: urlValue?.filter?.exams_type_id,
          edu_year_id: urlValue?.filter?.edu_year_id,
          query: searchVal ? searchVal : undefined, 
          filter: JSON.stringify(Object.keys(urlValue.filter)?.length ? {...urlValue.filter, status: 1} : undefined),
        }
      });

      res?.data?.data?.items?.forEach((element: any) => {

        let obj: any = {
          ["Guruh"]: element?.unical_name,
          ["Talabalar soni"]: element?.semestrStudentCount,
          ["Fakultet"]: element?.faculty?.name,
          ["Ta'lim rejasi	"]: element?.eduPlan?.name,
          // ["Semestr	"]: element?.semestr?.name,
        }

        eduSemestrSubjects?.forEach((eduSub: any) => {
          let currObj = element?.semestrSubject?.find((j: any) => j?.edu_semestr_subject_id == eduSub?.id);          
          obj[`${eduSub?.subject?.name} (o'tdi / o'tmadi)`] = `${currObj?.passed} / ${currObj?.no_passed}`
        })
    
        arr.push(obj)
      })
      setLoading(false);
      excelExport(arr, `Guruh bo'yicha fan baholari statistikasi`);
    } else {
      message.warning("Iltimos semestrni tanlang!!!")
    }

  }
  
  return (
    <div className="">
      <HeaderExtraLayout
        breadCrumbData={[
          { name: "Home", path: "/" },
          { name: "Guruh bo'yicha fan baholari", path: "" },
        ]}
        title={t("Guruh bo'yicha fan baholari")}
        btn={
          <div className="flex items-center">
            <ExcelBtn onClick={exportExcel} loading={loading} />
          </div>
        }
      />
      <div className="p-6">
        <Row gutter={[12, 12]}>
          {selectData?.map((e, i) => (
              <FilterSelect
                key={i}
                url={e.url}
                name={e.name}
                label={e.label}
                filter={e?.filter}
                permission={e.permission}
                parent_name={e?.parent_name}
                child_names={e?.child_names}
                value_name={e?.value_name}
                render={e?.render}
                onChange={e?.onChange}
                span={{ xs: 24, sm: 12, lg: 8, xl: 6 }}            
              />
          ))}
        </Row>

        <div className="mt-4">
          <Table
            columns={columns}
            dataSource={data?.items.length ? data?.items : allData}
            pagination={false}
            loading={isLoading}
            scroll={{ x: 765 }}
          />

          <CustomPagination
            totalCount={data?._meta.totalCount}
            currentPage={urlValue.currentPage}
            perPage={urlValue.perPage}
          />
        </div>
      </div>


    </div>
  )
}

export default GroupMarkStatistics;