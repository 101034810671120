import instance from "config/_axios";

export const changePassword = async ({password, new_password, new_password_again, user_id}:{password: string, new_password: string, new_password_again: string, user_id: number | undefined}) => {
   
    const formdata = new FormData();
    formdata.append("old_password", password);
    formdata.append("new_password", new_password);
    formdata.append("re_password", new_password_again);
  
    const response = await instance({ url: `/passwords/${user_id}`, method:"PUT", data: formdata });

    return response.data;
  };