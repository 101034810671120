import { useMutation } from "@tanstack/react-query";
import { Alert, Button, Form, Input, Modal, message, notification } from "antd"
import { Dispatch, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import { Notification } from "utils/notification";
import { changePassword } from "./request";
import { AxiosError } from "axios";
import { validationErrors } from "utils/validation_error";
import { checkPasswordStrength } from "utils/check_password_strength";

const ModalPasswordChange = ({isOpen, setIsOpen}: {isOpen?: boolean, setIsOpen?: Dispatch<boolean>}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const auth = useAppSelector(state => state.auth);
    const {t} = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    
    const openNotification = () => {
        api.info({
            message: `Parolingiz muvaffaqqiyatli o'zgartirildi!`,
            description: "Keyingi tizimga kirishingizda yangi paroldan foydalanishingiz mumkin!",
            placement: "topRight",
        });
    };


    const { mutate } = useMutation({
        mutationFn: (vals: { password: string, new_password: string, new_password_again: string }) => changePassword({...vals, user_id: auth?.user?.user_id}),
        onSuccess: async (res) => {            
          if(res?.status === 1){
            Notification("success", "update", res?.message);
            setIsModalOpen(false);
            openNotification()
          }
        },
        onError: (error: AxiosError<any>) => {
          message.error(error?.response?.data?.message);
          validationErrors(form, error?.response?.data)
        },
        retry: 0,
    });

    useEffect(() => {
        if(auth?.user){
            if(auth?.user?.change_password_type === 0){
                setIsModalOpen(true)
            } else {
                setIsModalOpen(false)
            }
        }
    }, [auth?.user?.change_password_type])

    const onFinish = ({password, new_password, new_password_again}: {password: string, new_password: string, new_password_again: string}) => {
        if(new_password !== new_password_again){
            form.setFields([{name: "new_password_again", errors: ["Yangi parollarda o'xshashlik yo'q!"]}])
        } else {

            if(checkPasswordStrength(new_password).strength > 3){
                mutate({password, new_password, new_password_again})
            } else {
                form.setFields([{name: "new_password", errors: [checkPasswordStrength(new_password).message]},{name: "new_password_again", errors: [checkPasswordStrength(new_password).message]}])
            }
        }
    }

    return (
        <>
            {contextHolder}
            <Modal title="Parolingizni o'zgartiring!" open={isModalOpen} width={1000} closable={false} onOk={() => setIsModalOpen(false)} footer={false}>
                <Alert type="warning" message={"Diqqat!"} description={
                    <div className="text-[16px]">Qo'shimcha xavfsizlik uchun tizimga kirish parolingizni yangilib olishingizni so'raymiz! 
                        <br />1. Joriy parol - hozirgi parolingiz 
                        <br />2. Yangi parol - minimum 6 ta belgidan iborat parol o'ylab topishingiz kerak bo'ladi
                        <br />3. Yangi parolni takrorlang - o'ylab topgan parolingizni takrorlashingiz kerak
                    </div>} />
                <Form 
                    onFinish={onFinish}
                    form={form} 
                    name="validateOnly" 
                    layout="vertical" 
                    autoComplete="off"
                    size="large"
                >
                    <Form.Item name="password" label="Joriy parol" rules={[{ required: true, message: "Joriy parolni kiriting"  }]}>
                        <Input.Password placeholder="Joriy parolni kiriting" />
                    </Form.Item>
                    <Form.Item name="new_password" label="Yangi parol" rules={[{ required: true, message: "Yangi parolni kiriting"  }]}>
                        <Input.Password minLength={6} maxLength={30} placeholder="Kamida 6 ta belgidan iborat bo'lishi kerak" />
                    </Form.Item>
                    <Form.Item name="new_password_again" label="Yangi parolni takrorlang" rules={[{ required: true, message: "Yangi parolni kiriting"  }]}>
                        <Input.Password minLength={6} maxLength={30} placeholder="Kamida 6 ta belgidan iborat bo'lishi kerak" />
                    </Form.Item>
                    <Form.Item className="flex justify-end mb-0">
                        <Button type="primary" htmlType="submit">{t("Submit")}</Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
export default ModalPasswordChange;