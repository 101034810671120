

export const checkPasswordStrength = (password: string) => {
    
    let strength = 0;
  
    // Uzunlikni tekshirish
    if (password.length >= 6) {
      strength += 1;
    }
  
    // Kichik harflar borligini tekshirish
    if (/[a-z]/.test(password)) {
      strength += 1;
    }
  
    // Katta harflar borligini tekshirish
    if (/[A-Z]/.test(password)) {
      strength += 1;
    }
  
    // Raqamlar borligini tekshirish
    if (/\d/.test(password)) {
      strength += 1;
    }
  
    // Maxsus belgilar borligini tekshirish
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      strength += 1;
    }
  
    let strengthMessage;
    switch (strength) {
      case 0:
      case 1:
        strengthMessage = 'Parolingiz juda zaif, iltimos kuchliroq parol yozing! (Kamida bitta katta harf, kamida bitta kichik harf, kamida bitta raqam, kamida bitta belgi)';
        break;
      case 2:
        strengthMessage = 'Parolingiz zaif, iltimos kuchliroq parol yozing! (Kamida bitta katta harf, kamida bitta kichik harf, kamida bitta raqam, kamida bitta belgi)';
        break;
      case 3:
        strengthMessage = 'O‘rtacha';
        break;
      case 4:
        strengthMessage = 'Kuchli';
        break;
      case 5:
        strengthMessage = 'Juda kuchli';
        break;
      default:
        strengthMessage = 'Noma’lum kuchlilik';
    }
  
    return {message: strengthMessage, strength};
  }