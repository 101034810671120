import React, { FC } from "react";
import { TextAlignRight24Filled, TextAlignLeft24Filled } from '@fluentui/react-icons'
import { changeSidebar } from "store/ui";
import { useAppDispatch, useAppSelector } from "store";
import { Badge, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { AlertRegular } from "@fluentui/react-icons";
import LanguageDropdown from "./components/selectLanguages";
import "./style.scss";
import UserDropdown from "./components/userDropdown";

const Header: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ui, auth } = useAppSelector(state => state);

  const selectTheme = React.useMemo(() => {
    if (ui.sidebar === "large") return "small";
    if (ui.sidebar === "small") return "large";
    // if (sidebar === "none") return "large";
    return "large"
  }, [ui.sidebar]);


  return (
    <div className="header-wrapper">
      <div className="left">
        <span className="burger" onClick={() => dispatch(changeSidebar(selectTheme))}>
          {
            ui.sidebar === "small" ? <div className="header-btn px-2"><TextAlignLeft24Filled className="burger" /></div>
              : <div className="header-btn px-2"><TextAlignRight24Filled className="burger" /></div>
          }
        </span>
      </div>
      <div className="right">
        {/* <DarkMode ui={ui} dispatch={dispatch}/> */}
          <Button type="text" className="px-[8px]">
            <AlertRegular className="text-[20px]" />
          </Button>
        <LanguageDropdown />
        {/* <SelectRole /> */}
        <UserDropdown />
      </div>
    </div>
  )
}

export default React.memo(Header);